import getOr from 'lodash/fp/getOr';
import { sendError } from '../setup/sentry';
import { changeLocale, displayMessagesFetched } from './actions';
import { DEFAULT_LOCALE, getSupportedLocale as defaultGetSupportedLocale } from './lang';

import { trace } from '../setup/trace';

// Webpack is weird sometimes, maybe it's Babel, who knows...
const normalizeDynamicImport = (imported) => getOr(imported, 'default', imported);

export const importDisplayMessages = (locale) =>
    import(/* webpackChunkName: "[request]" */ `../../../common/translations/${locale}.json`)
        .then(normalizeDynamicImport)
        .catch((error) => {
            sendError(error);
            return error;
        });

export const configureFetchDisplayMessages = (
    store,
    fetchDisplayMessages = importDisplayMessages,
    getSupportedLocale = defaultGetSupportedLocale
) => async (locale) => {
    if (!locale) {
        console.warn('No "locale" supplied when fetching display messages!');
        return Promise.reject();
    }

    const supportedLocale = getSupportedLocale(locale);
    try {
        const displayMessages = await fetchDisplayMessages(supportedLocale);
        trace(`Display messages fetched for "${supportedLocale}"`);
        store.dispatch(displayMessagesFetched(supportedLocale, displayMessages));
    } catch (error) {
        console.error(`Display messages for "${supportedLocale}" could not be fetched.`, error);
        sendError(error);
        store.dispatch(changeLocale(DEFAULT_LOCALE));
    }
};
